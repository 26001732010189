import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React from 'react';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';
import * as Styled from './slpPropaneHeaderWithImageStyle';

const SlpPropaneHeaderWithImage = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.AnchorButton
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
          }
          target={isExternalUrl(node?.data?.uri)? '_blank' : '_self'}
        >
          {children}
        </Styled.AnchorButton>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          asset?.node?.description ? (
            <Styled.LinkStyle
              href={asset?.node?.description}
              target={
                isExternalUrl(asset?.node?.description) ? '_blank' : '_self'
              }
            >
              <Styled.ImageStyle
                src={asset.node.file.url}
                alt={asset.node.title}
              />
            </Styled.LinkStyle>
          ) : (
            <Styled.ImageStyle
              $hasMargin={
                sectionData?.title === 'Hydraulic Oils and Compressor Lube'
                  ? false
                  : true
              }
              src={asset.node.file.url}
              alt={asset.node.title}
            />
          )
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <>
      {sectionData?.contentDetails &&
        documentToReactComponents(
          JSON.parse(sectionData?.contentDetails?.raw),
          optionsMainStyle
        )}
    </>
  );
};

export default SlpPropaneHeaderWithImage;
